import React from 'react'

export const CopyToClipboard: React.FC = () => (
  <svg
    width="26"
    height="29"
    viewBox="0 0 26 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5156 3.24872C11.3765 3.24872 10.4531 4.2938 10.4531 5.58298V6.75011H17.1562C18.0106 6.75011 18.7031 7.53392 18.7031 8.50081V19.5886H20.7656C21.9047 19.5886 22.8281 18.5435 22.8281 17.2543V5.58298C22.8281 4.2938 21.9047 3.24872 20.7656 3.24872H12.5156Z"
      fill="white"
    />
    <path
      d="M4.26562 10.5125C4.26562 9.54563 4.95818 8.76182 5.8125 8.76182H15.0938C15.9481 8.76182 16.6406 9.54563 16.6406 10.5125V22.1838C16.6406 23.1507 15.9481 23.9345 15.0938 23.9345H5.8125C4.95818 23.9345 4.26562 23.1507 4.26562 22.1838V10.5125Z"
      fill="white"
    />
  </svg>
)
