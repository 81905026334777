import React from 'react'

export const Arrow = ({
  expanded,
  onClick,
}: {
  expanded?: boolean
  onClick?: () => void
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={
          expanded
            ? 'M18.702 15.2344C18.3115 15.625 17.6783 15.625 17.2878 15.2345L11.9955 9.94215L6.71258 15.2339C6.32238 15.6247 5.68922 15.6252 5.29837 15.235C4.90752 14.8448 4.90699 14.2117 5.29719 13.8208L11.2865 7.82149C11.2865 7.82147 11.2872 7.82083 11.9949 8.52734L11.2865 7.82149C11.474 7.6337 11.7291 7.52745 11.9945 7.52734C12.2598 7.52723 12.5144 7.6326 12.702 7.82024L18.702 13.8202C19.0925 14.2108 19.0925 14.8439 18.702 15.2344Z'
            : 'M5.29778 7.81926C5.6883 7.42874 6.32147 7.42874 6.71199 7.81926L12.0043 13.1116L17.2872 7.81985C17.6774 7.429 18.3106 7.42847 18.7014 7.81867C19.0922 8.20887 19.0928 8.84203 18.7026 9.23288L12.7132 15.2322C12.7132 15.2322 12.7126 15.2329 12.0049 14.5264L12.7132 15.2322C12.5258 15.42 12.2707 15.5263 12.0053 15.5264C11.7399 15.5265 11.4854 15.4211 11.2978 15.2335L5.29778 9.23347C4.90725 8.84295 4.90725 8.20979 5.29778 7.81926Z'
        }
        fill="#0c0c0d"
      />
    </svg>
  )
}
