import React from 'react'
import { IconProps } from '../types'

export const SwitchCard: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 4.42022L5.79 1.05022C4.9 0.820223 3.98 1.35022 3.75 2.25022L2.78 6.00022H16.2C17.45 6.00022 18.53 6.70022 19.09 7.72022C19.22 7.95022 19.58 7.89022 19.64 7.63022L19.95 6.45022C20.18 5.56022 19.65 4.65022 18.75 4.42022Z"
        fill={primaryColor}
      />
      <path
        d="M16.21 7.50022H1.8C0.81 7.50022 0 8.31022 0 9.30022V18.3002C0 19.2902 0.81 20.1002 1.8 20.1002H16.2C17.19 20.1002 18 19.2902 18 18.3002V9.30022C18.01 8.31022 17.2 7.50022 16.21 7.50022ZM14.51 18.1002C14.13 18.1002 13.78 17.9502 13.52 17.7202C13.44 17.6502 13.33 17.6502 13.25 17.7202C12.99 17.9602 12.64 18.1002 12.26 18.1002C11.43 18.1002 10.76 17.4302 10.76 16.6002C10.76 15.7702 11.43 15.1002 12.26 15.1002C12.64 15.1002 12.99 15.2502 13.25 15.4802C13.33 15.5502 13.44 15.5502 13.52 15.4802C13.78 15.2402 14.13 15.1002 14.51 15.1002C15.34 15.1002 16.01 15.7702 16.01 16.6002C16.01 17.4302 15.33 18.1002 14.51 18.1002Z"
        fill={secondaryColor}
      />
    </svg>
  )
}
