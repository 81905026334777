import React from 'react'
import { IconProps } from '../types'

export const Website: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7778 0H2.22222C1 0 0 1 0 2.22222V13.7778C0 15 1 16 2.22222 16H17.7778C19 16 20 15 20 13.7778V2.22222C20 1 19.0111 0 17.7778 0ZM12.7 9.48889L11.4333 10L12.6556 11.2222C12.8889 11.4556 12.8889 11.8222 12.6556 12.0556L12.4556 12.2556C12.2222 12.4889 11.8556 12.4889 11.6222 12.2556L10.4 11.0333L9.88889 12.3C9.78889 12.5444 9.44444 12.5444 9.34444 12.3L7.14444 7.13333C7.04444 6.88889 7.28889 6.64444 7.53333 6.74444L12.7 8.93333C12.9444 9.04445 12.9444 9.38889 12.7 9.48889ZM18.1111 4.43333H1.88889C1.76667 4.43333 1.66667 4.33333 1.66667 4.21111V2.22222C1.66667 1.91111 1.91111 1.66667 2.22222 1.66667H17.7778C18.0889 1.66667 18.3333 1.91111 18.3333 2.22222V4.21111C18.3333 4.33333 18.2333 4.43333 18.1111 4.43333Z"
        fill={primaryColor}
      />
    </svg>
  )
}
