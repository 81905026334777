import React from 'react'
import { IconProps } from '../types'

export const Home: React.FC<IconProps> = ({ width, height, primaryColor }) => {
  return (
    <svg
      viewBox="0 0 21 20"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8802 9.72L11.4002 0.24L11.2802 0.12C11.1202 -0.04 10.8702 -0.04 10.7102 0.12L1.11023 9.72C0.950234 9.88 0.950234 10.13 1.11023 10.29L1.60023 10.78C1.76023 10.94 2.01023 10.94 2.17023 10.78L2.84023 10.11C2.88023 10.07 2.95023 10.07 2.99023 10.11V16.65C2.99023 18.31 4.33023 19.65 5.99023 19.65H8.09023C8.31023 19.65 8.49023 19.47 8.49023 19.25V14.56C8.49023 13.42 9.22023 12.37 10.3202 12.08C11.9902 11.65 13.4902 12.89 13.4902 14.49V19.25C13.4902 19.47 13.6702 19.65 13.8902 19.65H16.0002C17.6602 19.65 19.0002 18.31 19.0002 16.65V10.11C19.0402 10.07 19.1102 10.07 19.1502 10.11L19.8202 10.78C19.9802 10.94 20.2302 10.94 20.3902 10.78L20.8802 10.29C21.0402 10.13 21.0402 9.87 20.8802 9.72Z"
        fill={primaryColor}
      />
    </svg>
  )
}
