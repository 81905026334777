import React from 'react'
import { IconProps } from '../types'

export const Mgm: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
  secondaryColor,
  thirdColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5309 3C3.72442 3 3.23261 4.07444 3.65978 4.75849L5.76145 8.22571C6.06188 8.70681 5.91541 9.34037 5.4343 9.6408C4.9532 9.94123 4.31964 9.79477 4.01921 9.31366L1.91754 5.84645C0.63605 3 2.11148 1.00018 4.5309 1.00018H16.7556C16.8492 1.00018 16.9399 0.976706 17.0261 1.00018C17.706 1.1156 18.7134 1.70548 19.7022 2.81903C20.9122 4.18169 21.0687 6.96557 20.9787 8.52667C20.9595 8.86052 20.7603 9.15289 20.4671 9.31366L19.3422 9.93045C18.7986 10.2285 18.117 9.9807 17.8916 9.40323L15.5116 3H4.5309Z"
        fill={primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.42773 11H14.9998V21H3.18138C2.15468 21 2.12957 19.8886 1.99026 18.8714L1.01841 12.6931C0.849453 11.4594 1.18254 11 2.42773 11ZM7.48833 15.9409C8.08666 15.7294 8.51534 15.1588 8.51534 14.488C8.51534 13.6372 7.82563 12.9475 6.97482 12.9475C6.12402 12.9475 5.4343 13.6372 5.4343 14.488C5.4343 15.1588 5.86298 15.7294 6.46132 15.9409V17.2699C6.46132 17.5535 6.69122 17.7834 6.97482 17.7834C7.25843 17.7834 7.48833 17.5535 7.48833 17.2699V15.9409Z"
        fill={secondaryColor}
      />
      <path
        d="M15.9998 11H19C20.1344 11 20 11.2962 20 12.4306V19.4494C20 20.5838 19.6344 21 18.5 21H15.9998V11Z"
        fill={thirdColor}
      />
    </svg>
  )
}
