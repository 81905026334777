import React from 'react'

interface ExternalLinkProps {
  color?: string
}

const defaultProps: ExternalLinkProps = {
  color: '#0070E0',
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0859 6H13.5C12.9477 6 12.5 5.55228 12.5 5C12.5 4.44772 12.9477 4 13.5 4H19.5C20.0523 4 20.5 4.44772 20.5 5V11C20.5 11.5523 20.0523 12 19.5 12C18.9477 12 18.5 11.5523 18.5 11V7.41429L10.2072 15.7071C9.8167 16.0976 9.18353 16.0976 8.79301 15.7071C8.40248 15.3165 8.40248 14.6834 8.79301 14.2929L17.0859 6Z"
        fill={color}
      />
      <path
        d="M4.5 8.00001C4.5 7.44772 4.94772 7.00001 5.5 7.00001H11.5C12.0523 7.00001 12.5 7.44772 12.5 8.00001C12.5 8.55229 12.0523 9.00001 11.5 9.00001H6.5V18H15.5V13C15.5 12.4477 15.9477 12 16.5 12C17.0523 12 17.5 12.4477 17.5 13V19C17.5 19.5523 17.0523 20 16.5 20H5.5C4.94772 20 4.5 19.5523 4.5 19V8.00001Z"
        fill={color}
      />
    </svg>
  )
}

ExternalLink.defaultProps = defaultProps
