import React from 'react'
import './sucess-check-icon.css'

const SuccessCheckIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => {
  const checkmark_width_height =
    props?.type && props?.type === 'new'
      ? 'checkmark_new_width_height'
      : 'checkmark_old_width_height'

  return (
    <svg
      className={`checkmark ${checkmark_width_height}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      {...props}
    >
      <circle
        className="checkmark__circle"
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  )
}

export default SuccessCheckIcon
