import React from 'react'

interface ChevronDownProps {
  onClick?: () => void
}

export const ChevronDown = ({ onClick }: ChevronDownProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29778 7.81926C5.6883 7.42874 6.32147 7.42874 6.71199 7.81926L12.0043 13.1116L17.2872 7.81985C17.6774 7.429 18.3106 7.42847 18.7014 7.81867C19.0922 8.20887 19.0928 8.84203 18.7026 9.23288L12.7132 15.2322C12.7132 15.2322 12.7126 15.2329 12.0049 14.5264L12.7132 15.2322C12.5258 15.42 12.2707 15.5263 12.0053 15.5264C11.7399 15.5265 11.4854 15.4211 11.2978 15.2335L5.29778 9.23347C4.90725 8.84295 4.90725 8.20979 5.29778 7.81926Z"
        fill="#515354"
      />
    </svg>
  )
}
