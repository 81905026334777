import React from 'react'
import { IconProps } from '../types'

export const Flag: React.FC<IconProps> = ({ width, height, primaryColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79988 1.83891C2.79988 1.30871 3.22969 0.878906 3.75988 0.878906C4.29008 0.878906 4.71988 1.30871 4.71988 1.83891V2.79891H14.845C15.3621 2.79891 15.7106 3.32773 15.5067 3.80288L14.0664 7.15856C14.0118 7.28587 14.0148 7.43057 14.0747 7.55548L15.4428 10.4075C15.6721 10.8855 15.3237 11.4389 14.7936 11.4389H4.71988V18.1589H5.67988C6.21008 18.1589 6.63988 18.5887 6.63988 19.1189C6.63988 19.6491 6.21008 20.0789 5.67988 20.0789H1.83988C1.30969 20.0789 0.879883 19.6491 0.879883 19.1189C0.879883 18.5887 1.30969 18.1589 1.83988 18.1589H2.79988V1.83891Z"
        fill={primaryColor}
      />
    </svg>
  )
}
