import React from 'react'

interface ChevronRightProps {
  width?: string
  height?: string
  fill?: string
}

export const ChevronRight: React.FC<ChevronRightProps> = ({
  width = 30,
  height = 30,
  fill = 'none',
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29289 5.29289C7.90237 5.68342 7.90237 6.31658 8.29289 6.70711L13.5852 11.9994L8.29348 17.2823C7.90263 17.6725 7.90211 18.3057 8.2923 18.6965C8.6825 19.0874 9.31567 19.0879 9.70652 18.6977L15.7058 12.7084C15.7059 12.7083 15.7065 12.7077 15 12L15.7058 12.7084C15.8936 12.5209 15.9999 12.2658 16 12.0004C16.0001 11.7351 15.8947 11.4805 15.7071 11.2929L9.70711 5.29289C9.31658 4.90237 8.68342 4.90237 8.29289 5.29289Z"
        fill="#121661"
      />
    </svg>
  )
}
