import React from 'react'
import { IconProps } from '../../layout/Sidebar/types'

export const AccountBenefits: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.47891 7.75988L5.97891 7.83988C7.00891 7.84988 7.85891 7.00988 7.82891 5.97988L7.74891 2.47988C7.74891 2.16988 7.36891 2.00988 7.13891 2.23988L2.23891 7.13988C2.00891 7.36988 2.16891 7.74988 2.47891 7.75988Z"
        fill={primaryColor}
      />
      <path
        d="M18.5789 10.5499H16.2289V4.12988C16.2289 3.02988 15.3389 2.12988 14.2289 2.12988H9.21891C9.23891 2.22988 9.25891 2.33988 9.25891 2.44988L9.33891 5.94988C9.35891 6.84988 9.01891 7.69988 8.39891 8.33988C7.76891 8.97988 6.92891 9.33988 6.02891 9.33988L2.44891 9.25988C2.33891 9.24988 2.23891 9.21988 2.12891 9.19988V18.1299C2.12891 19.2299 3.02891 20.1299 4.12891 20.1299H7.38891C7.94891 21.1599 9.02891 21.8699 10.2789 21.8699H18.5689C20.3889 21.8699 21.8689 20.3899 21.8689 18.5699V13.8399C21.8789 12.0299 20.3989 10.5499 18.5789 10.5499ZM10.2789 12.0499H18.5689C18.8189 12.0499 19.0589 12.0999 19.2689 12.1899C19.4889 12.2799 19.6789 12.4099 19.8389 12.5799C19.9189 12.6599 19.9989 12.7499 20.0589 12.8499C20.2489 13.1399 20.3689 13.4799 20.3689 13.8599V14.3999H8.47891V13.8599C8.47891 12.8499 9.28891 12.0499 10.2789 12.0499ZM18.5789 20.3699H10.2789C10.1289 20.3699 9.97891 20.3399 9.83891 20.3099C9.81891 20.2999 9.79891 20.2999 9.77891 20.2899C9.63891 20.2499 9.51891 20.1999 9.38891 20.1299C8.84891 19.8199 8.47891 19.2399 8.47891 18.5699V16.9299H20.3689V18.5699C20.3789 19.5699 19.5689 20.3699 18.5789 20.3699Z"
        fill={secondaryColor}
      />
    </svg>
  )
}
