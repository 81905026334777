import React from 'react'
import { IconProps } from '../types'

export const CheckCapture: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16.5V12.5H1.5V16H5V17.5H1C0.447716 17.5 0 17.0523 0 16.5ZM1.5 2H5V0.5H1C0.447715 0.5 0 0.947716 0 1.5V5.5H1.5V2ZM18.5 2V5.5H20V1.5C20 0.947715 19.5523 0.5 19 0.5H15V2H18.5ZM18.5 16H15V17.5H19C19.5523 17.5 20 17.0523 20 16.5V12.5H18.5V16ZM3 5.5C3 4.67157 3.67157 4 4.5 4H15.5C16.3284 4 17 4.67157 17 5.5V12.5C17 13.3284 16.3284 14 15.5 14H4.5C3.67157 14 3 13.3284 3 12.5V5.5ZM4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H15.5C15.7761 7 16 6.77614 16 6.5C16 6.22386 15.7761 6 15.5 6H4.5ZM9.93421 11.7481C10.4118 10.9123 10.8675 10.4151 11.2418 10.1505C11.619 9.88387 11.8659 9.88178 11.9814 9.91641C12.0841 9.94723 12.1971 10.041 12.2454 10.2681C12.2949 10.5009 12.2642 10.8538 12.0529 11.2764C11.9294 11.5234 12.0295 11.8237 12.2765 11.9472C12.5235 12.0707 12.8238 11.9706 12.9473 11.7236C13.1728 11.2725 13.4108 10.995 13.6102 10.8412C13.8133 10.6845 13.9464 10.676 13.9919 10.6825C14.0165 10.686 14.0451 10.6933 14.0716 10.7633C14.1046 10.8501 14.1269 11.0385 14.0257 11.3419C13.9384 11.6039 14.08 11.887 14.342 11.9743C14.6039 12.0617 14.8871 11.9201 14.9744 11.6581C15.1233 11.2115 15.1456 10.7749 15.0066 10.4086C14.8613 10.0254 14.5462 9.75151 14.1333 9.69252C13.811 9.64648 13.4845 9.73815 13.1875 9.91961C13.0531 9.47337 12.7422 9.10061 12.2688 8.95859C11.7592 8.80573 11.1937 8.95988 10.6646 9.3339C10.1327 9.70992 9.58836 10.3377 9.06596 11.2519C8.92896 11.4917 9.01226 11.7971 9.25202 11.9341C9.49178 12.0711 9.7972 11.9878 9.93421 11.7481Z"
        fill={primaryColor}
      />
    </svg>
  )
}
