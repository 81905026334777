import React from 'react'
import { IconProps } from '../types'

export const DirectDeposit: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
  secondaryColor,
  thirdColor,
  fourthColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3.00098C21 2.44869 20.5523 2.00098 20 2.00098H4C3.44772 2.00098 3 2.44869 3 3.00098V10.9066C3 11.4589 3.44772 11.9066 4 11.9066L20 11.9066C20.5523 11.9066 21 11.4589 21 10.9066V3.00098ZM19.5 3.34863H4.5V10.184L19.5 10.184V3.34863Z"
        fill={primaryColor}
      />
      <path
        d="M3.16414 18.2221C2.94529 18.008 2.94529 17.6609 3.16414 17.4469L4.53311 16.1078C4.61966 16.0232 4.68313 15.9185 4.73266 15.8081C5.15963 14.8559 6.29748 14.1748 7.63412 14.1747V14.1733H13.7932C14.6444 14.1733 15.3344 14.8482 15.3344 15.6808C15.3344 16.5133 14.6444 17.0677 13.7932 17.0677L10.6408 17.0677L10.6321 17.0987C10.5777 17.2917 10.4981 17.574 10.3965 17.7376L14.6322 18.4459C15.1304 18.5292 15.6413 18.4107 16.052 18.1167L18.6083 16.2864C19.1554 15.7513 20.0425 15.7513 20.5896 16.2864C21.1368 16.8216 21.1368 17.6893 20.5896 18.2245L17.4599 20.621C16.8767 21.0676 16.1436 21.2718 15.4135 21.1911L9.3469 20.5207C9.1056 20.494 8.86558 20.5809 8.69719 20.7558L7.65426 21.8389C7.43541 22.0529 7.08057 22.0529 6.86172 21.8389L3.16414 18.2221Z"
        fill={secondaryColor}
      />
      <path
        d="M6.26115 5.3836C6.26115 4.97361 6.59694 4.64123 7.01115 4.64123H16.9888C17.4031 4.64123 17.7388 4.97361 17.7388 5.3836C17.7388 5.7936 17.4031 6.12597 16.9888 6.12597H7.01115C6.59694 6.12597 6.26115 5.7936 6.26115 5.3836Z"
        fill={thirdColor}
      />
      <path
        d="M7.01115 7.16315C6.59694 7.16315 6.26115 7.49551 6.26115 7.90551C6.26115 8.3155 6.59694 8.64787 7.01115 8.64787L12.4598 8.64787C12.874 8.64787 13.2098 8.3155 13.2098 7.90551C13.2098 7.49551 12.874 7.16315 12.4598 7.16315H7.01115Z"
        fill={fourthColor}
      />
    </svg>
  )
}
