import React from 'react'

interface IAcceptPaymentsProps {
  width?: string
  height?: string
}

const defaultProps: IAcceptPaymentsProps = {
  width: '72',
  height: '73',
}

export const AcceptPayments: React.FC<IAcceptPaymentsProps> = ({
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10.5C0 8.29086 1.79086 6.5 4 6.5H68C70.2091 6.5 72 8.29086 72 10.5V52.5C72 54.7091 70.2091 56.5 68 56.5H4C1.79086 56.5 0 54.7091 0 52.5V10.5Z"
        fill="#6FC400"
      />
      <path
        d="M8 44.5C8 42.2909 9.79086 40.5 12 40.5H60C62.2091 40.5 64 42.2909 64 44.5V68.5C64 70.7091 62.2091 72.5 60 72.5H12C9.79086 72.5 8 70.7091 8 68.5V44.5Z"
        fill="#5BBBFC"
      />
      <path
        d="M64 56.5H8V44.5C8 42.2909 9.79086 40.5 12 40.5H60C62.2091 40.5 64 42.2909 64 44.5V56.5Z"
        fill="#FAF8F5"
      />
      <path
        d="M48 56.5C48 63.1274 42.6274 68.5 36 68.5C29.3726 68.5 24 63.1274 24 56.5C24 49.8726 29.3726 44.5 36 44.5C42.6274 44.5 48 49.8726 48 56.5Z"
        fill="#001C64"
      />
      <path
        d="M4 0.5C1.79086 0.5 0 2.29086 0 4.5V12.5H72V4.5C72 2.29086 70.2091 0.5 68 0.5H4Z"
        fill="#005400"
      />
      <path
        d="M24 56.5C24 49.8726 29.3726 44.5 36 44.5C42.6274 44.5 48 49.8726 48 56.5H24Z"
        fill="#005400"
      />
      <path
        d="M37.7578 30.2755V19.499C37.7578 18.9467 37.3101 18.499 36.7578 18.499H35.2578C34.7055 18.499 34.2578 18.9467 34.2578 19.499V30.4485L29.4362 25.9106C29.0341 25.5321 28.4012 25.5512 28.0227 25.9534L26.9946 27.0457C26.6161 27.4479 26.6353 28.0808 27.0375 28.4593L35.3391 36.2726C35.7327 36.643 36.3494 36.6337 36.7316 36.2515L44.539 28.4441C44.9295 28.0536 44.9295 27.4204 44.539 27.0299L43.4783 25.9692C43.0878 25.5787 42.4546 25.5787 42.0641 25.9692L37.7578 30.2755Z"
        fill="#005400"
      />
      <path
        d="M8 6.5C8 7.60457 7.10457 8.5 6 8.5C4.89543 8.5 4 7.60457 4 6.5C4 5.39543 4.89543 4.5 6 4.5C7.10457 4.5 8 5.39543 8 6.5Z"
        fill="#FAF8F5"
      />
      <path
        d="M16 6.5C16 7.60457 15.1046 8.5 14 8.5C12.8954 8.5 12 7.60457 12 6.5C12 5.39543 12.8954 4.5 14 4.5C15.1046 4.5 16 5.39543 16 6.5Z"
        fill="#FAF8F5"
      />
      <path
        d="M22 8.5C23.1046 8.5 24 7.60457 24 6.5C24 5.39543 23.1046 4.5 22 4.5C20.8954 4.5 20 5.39543 20 6.5C20 7.60457 20.8954 8.5 22 8.5Z"
        fill="#FAF8F5"
      />
    </svg>
  )
}

AcceptPayments.defaultProps = defaultProps
