import React from 'react'

interface IAcceptCardsProps {
  width?: string
  height?: string
}

const defaultProps: IAcceptCardsProps = {
  width: '88',
  height: '56',
}

export const AcceptCards: React.FC<IAcceptCardsProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 88 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 20C28 17.7909 29.7909 16 32 16H84C86.2091 16 88 17.7909 88 20V52C88 54.2091 86.2091 56 84 56H32C29.7909 56 28 54.2091 28 52V20Z"
        fill="#6FC400"
      />
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H56C58.2091 0 60 1.79086 60 4V36C60 38.2091 58.2091 40 56 40H4C1.79086 40 0 38.2091 0 36V4Z"
        fill="#5BBBFC"
      />
      <path
        d="M60 16V36C60 38.2091 58.2091 40 56 40H28V20C28 17.7909 29.7909 16 32 16H60Z"
        fill="#FAF8F5"
      />
      <path d="M88 24H60V32H88V24Z" fill="#005400" />
      <path
        d="M51 49.5C51.5523 49.5 52 49.0523 52 48.5V47C52 46.4477 51.5523 46 51 46H33C32.4477 46 32 46.4477 32 47V48.5C32 49.0523 32.4477 49.5 33 49.5H51Z"
        fill="#005400"
      />
      <path
        d="M9 8C8.44772 8 8 8.44772 8 9V19C8 19.5523 8.44772 20 9 20H19C19.5523 20 20 19.5523 20 19V9C20 8.44772 19.5523 8 19 8H9Z"
        fill="#001C64"
      />
      <path
        d="M51.3967 23.9764C51.7436 23.5466 51.6764 22.917 51.2466 22.5702L50.0794 21.6281C49.6496 21.2812 49.0201 21.3484 48.6732 21.7782L42.2348 29.7551C41.8761 30.1995 41.2188 30.2538 40.792 29.8742L38.0733 27.4561C37.6606 27.0891 37.0285 27.126 36.6615 27.5387L35.6646 28.6595C35.2975 29.0722 35.3345 29.7043 35.7472 30.0713L41.2095 34.9296C41.6362 35.3092 42.2936 35.2549 42.6523 34.8105L51.3967 23.9764Z"
        fill="#001C64"
      />
    </svg>
  )
}

AcceptCards.defaultProps = defaultProps
