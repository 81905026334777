import React from 'react'
import { IconProps } from '../types'

export const Desktop: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 1.61929 1.11929 0.5 2.5 0.5H15.5C16.8807 0.5 18 1.61929 18 3V12C18 13.3807 16.8807 14.5 15.5 14.5H10V16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H4C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16H8V14.5H2.5C1.11929 14.5 0 13.3807 0 12V3ZM3 10.5C2.44772 10.5 2 10.9477 2 11.5C2 12.0523 2.44772 12.5 3 12.5H15C15.5523 12.5 16 12.0523 16 11.5C16 10.9477 15.5523 10.5 15 10.5H3Z"
        fill={primaryColor}
      />
    </svg>
  )
}
