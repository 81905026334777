import React from 'react'

interface ChevronUpProps {
  onClick?: () => void
}

export const ChevronUp = ({ onClick }: ChevronUpProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.702 15.2344C18.3115 15.625 17.6783 15.625 17.2878 15.2345L11.9955 9.94215L6.71258 15.2339C6.32238 15.6247 5.68922 15.6252 5.29837 15.235C4.90752 14.8448 4.90699 14.2117 5.29719 13.8208L11.2865 7.82149C11.2865 7.82147 11.2872 7.82083 11.9949 8.52734L11.2865 7.82149C11.474 7.6337 11.7291 7.52745 11.9945 7.52734C12.2598 7.52723 12.5144 7.6326 12.702 7.82024L18.702 13.8202C19.0925 14.2108 19.0925 14.8439 18.702 15.2344Z"
        fill="#515354"
      />
    </svg>
  )
}
