import React from 'react'
import { IconProps } from '../types'

export const BillPayment: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C14.3807 0 15.5 1.11929 15.5 2.5V17.5C15.5 18.8807 14.3807 20 13 20H3C1.61929 20 0.5 18.8807 0.5 17.5V2.5C0.5 1.11929 1.61929 0 3 0H13ZM9.875 15H3.625C3.27982 15 3 15.2798 3 15.625C3 15.9702 3.27982 16.25 3.625 16.25H9.875C10.2202 16.25 10.5 15.9702 10.5 15.625C10.5 15.2798 10.2202 15 9.875 15ZM12.375 15C12.0298 15 11.75 15.2798 11.75 15.625C11.75 15.9702 12.0298 16.25 12.375 16.25C12.7202 16.25 13 15.9702 13 15.625C13 15.2798 12.7202 15 12.375 15ZM9.875 12.5H3.625C3.27982 12.5 3 12.7798 3 13.125C3 13.4702 3.27982 13.75 3.625 13.75H9.875C10.2202 13.75 10.5 13.4702 10.5 13.125C10.5 12.7798 10.2202 12.5 9.875 12.5ZM12.375 12.5C12.0298 12.5 11.75 12.7798 11.75 13.125C11.75 13.4702 12.0298 13.75 12.375 13.75C12.7202 13.75 13 13.4702 13 13.125C13 12.7798 12.7202 12.5 12.375 12.5ZM9.875 10H3.625C3.27982 10 3 10.2798 3 10.625C3 10.9702 3.27982 11.25 3.625 11.25H9.875C10.2202 11.25 10.5 10.9702 10.5 10.625C10.5 10.2798 10.2202 10 9.875 10ZM12.375 10C12.0298 10 11.75 10.2798 11.75 10.625C11.75 10.9702 12.0298 11.25 12.375 11.25C12.7202 11.25 13 10.9702 13 10.625C13 10.2798 12.7202 10 12.375 10ZM7.375 3.75H3.625C3.27982 3.75 3 4.02982 3 4.375V5.625C3 5.97018 3.27982 6.25 3.625 6.25H7.375C7.72018 6.25 8 5.97018 8 5.625V4.375C8 4.02982 7.72018 3.75 7.375 3.75Z"
        fill={primaryColor}
      />
    </svg>
  )
}
