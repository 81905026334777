import React from 'react'

interface ArrowRightProps {}

export const ArrowRight: React.FC<ArrowRightProps> = ({}) => {
  return (
    <svg
      width="42"
      height="32"
      viewBox="0 0 42 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="16" r="14" fill="#9EE0FE" fillOpacity="0.4" />
      <path
        d="M24.7096 8.38721C24.4046 8.77955 24.4325 9.34677 24.793 9.70721L30.087 14.999L7 15C6.44772 15 6 15.4477 6 16L6.00673 16.1166C6.06449 16.614 6.48716 17 7 17L30.084 16.999L24.7937 22.293C24.4033 22.6837 24.4034 23.3168 24.794 23.7073C25.1846 24.0977 25.8178 24.0976 26.2082 23.707L33.2073 16.705C33.5977 16.3144 33.5976 15.6813 33.207 15.2908L26.207 8.29279C25.8164 7.90232 25.1833 7.90241 24.7928 8.29299L24.7096 8.38721Z"
        fill="#1040C1"
      />
    </svg>
  )
}
