import React from 'react'
import { IconProps } from '../types'

export const Merchant: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7 6.16909L15 1.49252H15.3C15.7 1.49252 16 1.19402 16 0.796012C16 0.298505 15.6 0 15.2 0H4.7C4.3 0 4 0.298505 4 0.696511C4 1.09452 4.3 1.39302 4.7 1.39302H5L0.3 6.16909C0.1 6.3681 0 6.5671 0 6.8656V7.46261C0 8.55713 0.9 9.45264 2 9.45264C3.1 9.45264 4 8.55713 4 7.46261C4 8.55713 4.9 9.45264 6 9.45264C7.1 9.45264 8 8.55713 8 7.46261C8 8.55713 8.9 9.45264 10 9.45264C11.1 9.45264 12 8.55713 12 7.56211C12 8.65663 12.9 9.45264 14 9.45264C15.1 9.45264 16 8.55713 16 7.46261C16 8.55713 16.9 9.45264 18 9.45264C19.1 9.45264 20 8.55713 20 7.46261V6.8656C20 6.5671 19.9 6.3681 19.7 6.16909Z"
        fill={primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10.4479V18.408C2 20 3.2 20 4.8 20C4.9 20 5 20 5 20H6.4C6.7 20 7 19.7015 7 19.403V15.6219C7 13.9304 8.3 12.6369 10 12.6369C11.7 12.6369 13 13.9304 13 15.6219V19.403C13 19.7015 13.3 20 13.6 20H15C15.1 20 15.2 20 15.2 20C16.7 20 18 20 18 18.408V10.4479C17.2 10.4479 16.5 10.1493 16 9.65184C15.5 10.1493 14.8 10.4479 14 10.4479C13.2 10.4479 12.5 10.1493 12 9.65184C11.5 10.1493 10.8 10.4479 10 10.4479C9.2 10.4479 8.5 10.1493 8 9.65184C7.5 10.1493 6.8 10.4479 6 10.4479C5.2 10.4479 4.5 10.1493 4 9.65184C3.5 10.1493 2.8 10.4479 2 10.4479Z"
        fill={secondaryColor}
      />
    </svg>
  )
}
