import React from 'react'

interface IArrowLeftProps {
  color?: string
}

const defaultProps: IArrowLeftProps = {
  color: '#142C8E',
}

export const ArrowLeft: React.FC<IArrowLeftProps> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03906 11.5303C3.03906 10.978 3.48678 10.5303 4.03906 10.5303H19.999C20.5513 10.5303 20.999 10.978 20.999 11.5303C20.999 12.0826 20.5513 12.5303 19.999 12.5303H4.03906C3.48678 12.5303 3.03906 12.0826 3.03906 11.5303Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7462 4.82317C11.1367 5.21369 11.1367 5.84686 10.7462 6.23738L5.45387 11.5297L10.7456 16.8126C11.1364 17.2028 11.137 17.8359 10.7468 18.2268C10.3566 18.6176 9.7234 18.6182 9.33255 18.228L3.33255 12.238C3.14475 12.0505 3.03917 11.7956 3.03906 11.5303C3.03895 11.2649 3.14432 11.0108 3.33196 10.8232L9.33196 4.82317C9.72248 4.43264 10.3556 4.43264 10.7462 4.82317Z"
        fill={color}
      />
    </svg>
  )
}
ArrowLeft.defaultProps = defaultProps
