import React from 'react'
import { IconProps } from '../types'

export const PackageSize: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
  secondaryColor,
  thirdColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 7.3125L12 3.5625L19.5 7.3125V16.6875L12 20.4375L4.5 16.6875V7.3125ZM5.73751 8.28264C5.89187 7.9739 6.2673 7.84876 6.57603 8.00313L11.9996 10.7149L17.4233 8.00313C17.732 7.84876 18.1074 7.9739 18.2618 8.28264C18.4162 8.59138 18.291 8.9668 17.9823 9.12117L12.6245 11.8001V18.062C12.6245 18.4072 12.3447 18.687 11.9995 18.687C11.6543 18.687 11.3745 18.4072 11.3745 18.062V11.7999L6.01701 9.12117C5.70828 8.9668 5.58314 8.59138 5.73751 8.28264Z"
        fill={primaryColor}
      />
      <path
        d="M20.8799 2.06738C21.5012 2.06738 22.0049 2.57106 22.0049 3.19238V5.69238C22.0049 6.03756 21.7251 6.31738 21.3799 6.31738C21.0347 6.31738 20.7549 6.03756 20.7549 5.69238V3.31738H18.3799C18.0347 3.31738 17.7549 3.03756 17.7549 2.69238C17.7549 2.3472 18.0347 2.06738 18.3799 2.06738H20.8799Z"
        fill={secondaryColor}
      />
      <path
        d="M3.12988 22.0674C2.50856 22.0674 2.00488 21.5637 2.00488 20.9424V18.4424C2.00488 18.0972 2.28471 17.8174 2.62988 17.8174C2.97506 17.8174 3.25488 18.0972 3.25488 18.4424L3.25488 20.8174H5.62988C5.97506 20.8174 6.25488 21.0972 6.25488 21.4424C6.25488 21.7876 5.97506 22.0674 5.62988 22.0674H3.12988Z"
        fill={thirdColor}
      />
    </svg>
  )
}
