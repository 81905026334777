import React from 'react'
import { IconProps } from '../types'

export const Rocket: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4767 16.0755C13.0835 15.2344 15.2373 13.6387 16.8527 12.0234C20.138 8.73801 21.1015 5.75441 21.3689 4.02786C21.5091 3.12239 20.8039 2.41714 19.8984 2.55738C18.1719 2.82479 15.1883 3.78827 11.9029 7.07361C10.2876 8.68894 8.69188 10.8428 7.85075 12.4495C7.85075 12.4495 7.74598 14.059 8.80664 15.1196C9.8673 16.1803 11.4767 16.0755 11.4767 16.0755ZM16.2312 7.69502C16.817 8.28081 17.7668 8.28081 18.3525 7.69502C18.9383 7.10923 18.9383 6.15949 18.3525 5.5737C17.7668 4.98791 16.817 4.98791 16.2312 5.5737C15.6454 6.15949 15.6454 7.10923 16.2312 7.69502ZM12.7218 16.5215C12.9452 17.1767 13.0832 17.9219 13.1573 18.5968C13.2036 19.0179 13.4855 19.3349 13.8325 19.4572C14.1893 19.583 14.6246 19.5022 14.9063 19.1412C15.8444 17.9389 17.3596 15.5991 17.23 13.0533C15.8532 14.3729 14.1729 15.6516 12.7218 16.5215ZM11.1447 6.41792C11.0959 6.40366 11.0445 6.39277 10.9905 6.38562C8.26534 6.02483 5.70772 7.67062 4.43164 8.66627C4.07057 8.94798 3.98977 9.38328 4.11561 9.74016C4.23793 10.0871 4.5549 10.369 4.97599 10.4153C5.81514 10.5075 6.76277 10.6984 7.51121 11.0293C8.43898 9.51884 9.78348 7.78857 11.1447 6.41792ZM6.69656 14.7215C6.85039 15.1061 6.66333 15.5426 6.27874 15.6964L3.77874 16.6964C3.39415 16.8502 2.95768 16.6632 2.80384 16.2786C2.65001 15.894 2.83707 15.4575 3.22166 15.3037L5.72166 14.3037C6.10624 14.1499 6.54272 14.3369 6.69656 14.7215ZM7.53057 17.5304C7.82347 17.2375 7.82347 16.7626 7.53057 16.4697C7.23768 16.1768 6.76281 16.1768 6.46991 16.4697L3.46991 19.4697C3.17702 19.7626 3.17702 20.2375 3.46991 20.5304C3.76281 20.8233 4.23768 20.8233 4.53057 20.5304L7.53057 17.5304ZM9.69656 18.2786C9.85039 17.894 9.66333 17.4575 9.27874 17.3037C8.89416 17.1499 8.45768 17.3369 8.30384 17.7215L7.30384 20.2215C7.15001 20.6061 7.33707 21.0426 7.72166 21.1964C8.10624 21.3502 8.54272 21.1632 8.69656 20.7786L9.69656 18.2786Z"
        fill={primaryColor}
      />
    </svg>
  )
}
