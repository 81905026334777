import React from 'react'
import { IconProps } from '../types'

export const ShippingPack: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0307 8.03034C11.7378 8.32323 11.263 8.32323 10.9701 8.03034L9.59263 6.6529L8.55176 5.61203C8.25887 5.31914 8.25887 4.84427 8.55176 4.55137C8.84466 4.25848 9.31953 4.25848 9.61242 4.55137L10.6687 5.60762V2.75C10.6687 2.33579 11.0045 2 11.4187 2C11.8329 2 12.1687 2.33579 12.1687 2.75V5.77108L13.3884 4.55137C13.6813 4.25848 14.1561 4.25848 14.449 4.55137C14.7419 4.84427 14.7419 5.31914 14.449 5.61204L13.4081 6.65292L12.0307 8.03034ZM4.98295 8.38153L8.30698 7.13502L10.0862 8.91422C10.8672 9.69527 12.1336 9.69527 12.9146 8.91422L14.6938 7.13504L18.0178 8.38153L12.1738 11.3179C11.9766 11.417 11.763 11.4699 11.5483 11.4768C11.5325 11.4756 11.5165 11.475 11.5004 11.475C11.4842 11.475 11.4682 11.4756 11.4524 11.4768C11.2377 11.4699 11.0241 11.417 10.8269 11.3179L4.98295 8.38153ZM4.00036 9.05908L3.38477 8.75001L0.884766 10.75L4.00036 12.3143L8.38477 14.5156L10.8754 12.5232V12.5109L4.00036 9.05908ZM22.1204 10.75L19.6204 8.75001L19.0004 9.06129L12.1254 12.5131V12.5196L14.6204 14.5156L19.0004 12.3165L22.1204 10.75ZM19.0004 13.4355V18.125L11.5004 21.875L4.00036 18.125V13.4333L7.93606 15.4093C8.28518 15.5846 8.70441 15.5405 9.00946 15.2965L10.8754 13.8038V19.5C10.8754 19.8452 11.1552 20.125 11.5004 20.125C11.8455 20.125 12.1254 19.8452 12.1254 19.5V13.8002L13.9957 15.2965C14.3007 15.5405 14.72 15.5846 15.0691 15.4093L19.0004 13.4355Z"
        fill={primaryColor}
      />
    </svg>
  )
}
