import React from 'react'
import { IconProps } from '../types'

export const Qrc: React.FC<IconProps> = ({
  width,
  height,
  primaryColor,
  secondaryColor,
  thirdColor,
  fourthColor,
  fifthColor,
  sixthColor,
  seventhColor,
  eighthColor,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.01 2.01H16.19C16.18 2.01 16.17 2 16.16 2H15.85C15.52 2 15.25 2.27 15.25 2.6V2.91C15.25 3.24 15.52 3.51 15.85 3.51H19C19.83 3.51 20.5 4.18 20.5 5.01V7.83C20.5 7.84 20.49 7.85 20.49 7.86V8.17C20.49 8.5 20.76 8.77 21.09 8.77H21.4C21.73 8.77 22 8.5 22 8.17V5.01C22.01 3.35 20.66 2.01 19.01 2.01Z"
        fill={primaryColor}
      />
      <path
        d="M8.16 20.4998H7.85C7.83 20.4998 7.82 20.5098 7.8 20.5098H5C4.17 20.5098 3.5 19.8398 3.5 19.0098V15.8598C3.5 15.5298 3.23 15.2598 2.9 15.2598H2.6C2.27 15.2598 2 15.5298 2 15.8598V16.1698C2 16.1798 2.01 16.1898 2.01 16.2098V19.0098C2.01 20.6698 3.35 22.0098 5.01 22.0098H8.16C8.49 22.0098 8.76 21.7398 8.76 21.4098V21.0998C8.76 20.7598 8.49 20.4998 8.16 20.4998Z"
        fill={secondaryColor}
      />
      <path
        d="M21.41 15.25H21.1C20.77 15.25 20.5 15.52 20.5 15.85V16.16C20.5 16.17 20.51 16.18 20.51 16.19V19.01C20.51 19.84 19.84 20.51 19.01 20.51H16.21C16.19 20.51 16.18 20.5 16.16 20.5H15.85C15.52 20.5 15.25 20.77 15.25 21.1V21.41C15.25 21.74 15.52 22.01 15.85 22.01H19C20.66 22.01 22 20.67 22 19.01V15.86C22.01 15.52 21.74 15.25 21.41 15.25Z"
        fill={thirdColor}
      />
      <path
        d="M2.6 8.76977H2.91C3.24 8.76977 3.51 8.49977 3.51 8.16977V5.00977C3.51 4.17977 4.18 3.50977 5.01 3.50977H7.81C7.82 3.50977 7.83 3.51977 7.85 3.51977H8.16C8.49 3.51977 8.76 3.24977 8.76 2.91977V2.60977C8.76 2.27977 8.49 2.00977 8.16 2.00977H5.01C3.35 2.00977 2.01 3.34977 2.01 5.00977V7.82977C2.01 7.83977 2 7.84977 2 7.85977V8.16977C2 8.49977 2.27 8.76977 2.6 8.76977Z"
        fill={fourthColor}
      />
      <path
        d="M16.7113 6H14.2887C13.5799 6 13 6.5799 13 7.28866V9.71134C13 10.4201 13.5799 11 14.2887 11H16.7113C17.4201 11 18 10.4201 18 9.71134V7.28866C18 6.5799 17.4201 6 16.7113 6Z"
        fill={fifthColor}
      />
      <path
        d="M9.71134 6H7.28866C6.5799 6 6 6.5799 6 7.28866V9.71134C6 10.4201 6.5799 11 7.28866 11H9.71134C10.4201 11 11 10.4201 11 9.71134V7.28866C11 6.5799 10.4201 6 9.71134 6Z"
        fill={sixthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.708 14H14.292C14.1337 14 14 14.1337 14 14.292V16.708C14 16.8663 14.1337 17 14.292 17H16.708C16.8663 17 17 16.8663 17 16.708V14.2791C17 14.1338 16.8795 14 16.708 14ZM14.292 13H16.708C17.4186 13 18 13.5685 18 14.2791V16.708C18 17.4186 17.4186 18 16.708 18H14.292C13.5814 18 13 17.4186 13 16.708V14.292C13 13.5814 13.5814 13 14.292 13Z"
        fill={seventhColor}
      />
      <path
        d="M9.71134 13H7.28866C6.5799 13 6 13.5814 6 14.292V16.708C6 17.4186 6.5799 18 7.28866 18H9.71134C10.4201 18 11 17.4186 11 16.708V14.2791C11 13.5685 10.4201 13 9.71134 13Z"
        fill={eighthColor}
      />
    </svg>
  )
}
