import React from 'react'

const CopyPasteIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.63965H19.5C20.3284 3.63965 21 4.29847 21 5.11117V16.8833C21 17.696 20.3284 18.3548 19.5 18.3548H8C7.17157 18.3548 6.5 17.696 6.5 16.8833V5.11117C6.5 4.29847 7.17157 3.63965 8 3.63965ZM16.5 19.4238C16.5 19.1529 16.7239 18.9333 17 18.9333C17.2761 18.9333 17.5 19.1529 17.5 19.4238V19.8264C17.5 20.6391 16.8284 21.2979 16 21.2979H4.5C3.67157 21.2979 3 20.6391 3 19.8264V8.05421C3 7.24151 3.67157 6.58269 4.5 6.58269H5.01289C5.28903 6.58269 5.51289 6.80229 5.51289 7.07319C5.51289 7.34409 5.28903 7.5637 5.01289 7.5637H4.5C4.22386 7.5637 4 7.78331 4 8.05421V19.8264C4 20.0973 4.22386 20.3169 4.5 20.3169H16C16.2761 20.3169 16.5 20.0973 16.5 19.8264V19.4238ZM7.5 5.11117C7.5 4.84027 7.72386 4.62066 8 4.62066H19.5C19.7761 4.62066 20 4.84027 20 5.11117V16.8833C20 17.1542 19.7761 17.3738 19.5 17.3738H8C7.72386 17.3738 7.5 17.1542 7.5 16.8833V5.11117Z"
      fill="#00CF92"
    />
  </svg>
)

export default CopyPasteIcon
